import React from "react";
import ImageGallery from "react-image-gallery";
const Carousel = ({ items }) => {
  if (!items || items.length === 0 || items.filter(item => item.hasOwnProperty("original")).length === 0) {
    return <div />;
  }

  const NavController = ({ direction, onClick, disabled }) => {
    const notation = direction === "right" ? ">" : "<";
    var style = direction === "right" ? { right: 20 } : { left: 0 };
    return (
      <div
        disabled={disabled}
        onClick={() => {
          onClick();
        }}
        style={{
          height: "100%",
          width: 50,
          position: "absolute",
          top: 0,
          bottom: 0,
          padding: 20,
          margin: "auto",
          zIndex: 100,
          color: "white",
          fontSize: "3rem",
          ...style,
          display: "flex",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>{notation}</p>
      </div>
    );
  };
  return (
    <div style={{ minHeight: 200, marginTop: 10 }}>
      <ImageGallery
        items={items}
        autoPlay={true}
        showPlayButton={false}
        showFullscreenButton={false}
        lazyLoad={true}
        renderLeftNav={(onClick, disabled) => <NavController onClick={onClick} disabled={disabled} direction="left" />}
        renderRightNav={(onClick, disabled) => (
          <NavController onClick={onClick} disabled={disabled} direction="right" />
        )}
      />
    </div>
  );
};
export default Carousel;
