import React, { useContext } from "react";
import { AppContext } from "Contexts";
const Availability = ({
  isWeekend = false,
  available,
  start,
  end,
  isBoth = false,
  chaletName = "",
  arabicChaletName = "",
}) => {
  const context = useContext(AppContext);
  // logic to get the data and check
  const properties = available.split(" ");

  const onClick = () => {
    context.setAlertData({
      fromDate: start.format("DD/MM/YYYY"),
      toDate: end.clone().subtract(1, "days").format("DD/MM/YYYY"),
      properties,
      chaletName: arabicChaletName,
    });
  };

  if (available && available.length > 0) {
    return (
      <>
        <td colSpan={isWeekend ? 3 : 4} className="periodAvailabilityContainer no-select">
          {isBoth ? (
            <h2
              className="periodAvailable"
              style={{ display: "flex", justifyContent: "space-between" }}
              onClick={onClick}
            >
              <div>{chaletName}</div>
              <div>{properties.join(" ")}</div>
              <div style={{ color: "transparent" }}>{chaletName}</div>
            </h2>
          ) : (
            <h2 className="periodAvailable" onClick={onClick}>
              {properties.join(" ")}
            </h2>
          )}
        </td>
      </>
    );
  }
  return (
    <>
      <td colSpan={isWeekend ? 3 : 4} className="periodAvailabilityContainer no-select">
        <h2 className="periodUnavailable">Not Available</h2>
      </td>
    </>
  );
};

export default Availability;
